import React from "react";
// import masks from './masks.svg'
import { Link } from 'react-router-dom';


export default function Footy() {
    const CCImg = (props) => {
        return (
            <img
                class="d-none d-lg-inline"
                style={{
                    height: '2em',
                    marginLeft: 3,
                    verticalAlign: "text-bottom"
                }}
                src={props.src}
                alt={props.alt}
            />);
    }

    return (
        <>
            <hr class="mt-5"></hr>
            <footer class="container-fluid">
                <div class="row">
                    <div class="text-center text-md-start col-sm-12 col-md-6">
                        <p
                            xmlnsCc="http://creativecommons.org/ns#"
                            xmlnsDct="http://purl.org/dc/terms/"
                        >
                            <a
                                aria-label="Link to this website's home page"
                                property="dct:title"
                                rel="cc:attributionURL"
                                href="http://madrid.callahans.net"
                            >
                                Learning Language Through Theater
                            </a>{" "}
                            is licensed under{" "}
                            <a
                                href="http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
                                rel="license noopener noreferrer"
                                style={{ display: "inline-block" }}
                            >
                                <abbr title="Attribution-NonCommercial-ShareAlike 4.0 International">CC BY-NC-SA 4.0</abbr>
                                <CCImg src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="Creative Commons Icon" />
                                <CCImg src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="Creative Commons Icon: Attribution" />
                                <CCImg src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1" alt="Creative Commons Icon: Noncommercial" />
                                <CCImg src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1" alt="Creative Commons Icon: Share-Alike" />

                            </a>
                        </p>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-sm-12 col-md-5">
                        <p class="float-end ms-3"><a href="#top">Back to top</a></p>
                        <p><Link to="/sitemap#top">Site Map</Link> &middot; <Link to="/accessibility#top">Accessibility</Link></p>
                    </div>
                </div>
            </footer>
        </>
    );
}