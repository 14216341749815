import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import masks from './masks.svg';
import '../components/cover.css';
import { useLocation } from 'react-router-dom';


const headings = {
  'Home': '/',
  'Activity': '/activity',
  'Resources': '/resources'
}

function Navvy() {
  const path = useLocation().pathname;

  // return aria-current value for heading based on path
  const isAriacurrent = (heading) => {
    if (headings[heading] === path) {
      return 'page'
    } else {
      return 'false'
    }
  }

  const isActive = (heading) => {
    if (headings[heading] === path) {
      return 'active'
    } else {
      return ''
    }
  }

  return (
    <header class="mb-auto">
      <div class="container-fluid">
        <div class="justify-content-center float-sm-start mb-0 hstack pe-lg-5 pe-xl-5 pe-xxl-5 pt-2">
          <img class="mx-1 my-sm-1"
            alt="Theater masks icon"
            src={masks}
            width="40"
            height="40"
          />
          <p class="px-3 my-1 d-sm-none d-md-block">Learning Language<br></br>Through Theater</p>
        </div>
        <nav class="mx-3 py-2 justify-content-center nav nav-masthead float-sm-end">
          {Object.keys(headings).map(heading => (
            <>
              <Link
                aria-current={isAriacurrent(heading)}
                to={headings[heading]}
                className={"nav-link navvy-link py-2 px-0 " + isActive(heading)}
              >
                {heading}
              </Link>
            </>
          ))}
        </nav>
      </div>
    </header>
  )
}

/*
<Navbar expand="md" class="navbar" role="navigation">
      <div class="container-fluid">
        <div class="hstack pe-lg-5 pe-xl-5 pe-xxl-5">
          <Navbar.Brand>
            <img
              alt="Theater masks icon"
              src={masks}
              width="40"
              height="40"
            />
          </Navbar.Brand>
          <Navbar.Text class="d-none d-sm-block">Learning Language<br></br>Through Theater</Navbar.Text>
        </div>
        <Navbar.Toggle id="navbarSupportedContent"></Navbar.Toggle>
        <Navbar.Collapse class="justify-content-end" className="justify-content-end" id="navbarSupportedContent">
          <Nav class="navbar-nav nav-masthead">
            {Object.keys(headings).map(heading => (
              <>
                <Nav.Link 
                  aria-current={isAriacurrent(heading)}
                  as={Link}
                  to={headings[heading]}
                  className={"nav-link py-1 px-0 " + isActive(heading)}
                >
                  {heading}
                </Nav.Link>
              </>
            ))}
          </Nav>

        </Navbar.Collapse>
      </div>

    </Navbar>


*/

export default Navvy;