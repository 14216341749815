import { React, useEffect, useRef } from 'react';
import {
    Outlet,
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route, useLocation
} from "react-router-dom";
import Navvy from './components/Navvy.js';
import Home from './pages/Home.js';
import { Resources } from './pages/Resources.js';
import Activity from './pages/Activity.js';
import Accessibility from './pages/Accessibility.js';
import Footy from './components/Footy.js';
import Sitemap from './pages/Sitemap.js';
import './App.css';
import './components/cover.css'
import { ScrollRestoration } from "react-router-dom";
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer.js';
import ScrollToAnchor from './components/ScrollToAnchor.js';

const router = createBrowserRouter([
    {
        path: "*",
        element: (<Root />),
      },
      {
        path: "*",
        element: (<Layout />),
        children: [
            {
                path: "*/",
                element: (<Home />),
              },
            {
                path: "*/activity", 
                element: (<Activity />),
              },
              {
                  path: "*/resources", 
                  element: (<Resources />),
                },
                {
                  path: "*/accessibility", 
                  element: (<Accessibility />),
                },
                {
                  path: "*/site_map", 
                  element: (<Sitemap />),
                },
        ]
    },
  ]);

function Layout() {
    return(
        <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
                  <ScrollRestoration />
                  <ScrollToAnchor />
                  <div class="visually-hidden">
                  <AccessibleNavigationAnnouncer/>

                  </div>
                <Navvy />
                <main>
                    <Outlet/>
                </main>
                <Footy />
        </div>
    );
}
function Root() {
    return(
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/activity" element={<Activity />} />
                    <Route path="/resources" element={<Resources />} />
                    <Route path="/accessibility" element={<Accessibility />} />
                    <Route path="/sitemap" element={<Sitemap />} />
                </Route>
            </Routes>
    );
}
function App() {

    return (
        <RouterProvider
        router={router}
        />
    );
}

export default App