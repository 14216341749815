import React from "react";
import '../components/cover.css';
import { Link } from 'react-router-dom';
import image from '../components/props.jpeg';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import presentation from '../components/intro-pres.pdf';

// svg taken from bootstrap icons
const download = (
    <svg aria-hidden="true" style={{ marginRight: 3, marginLeft: 3 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
    </svg>
)

function PPModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link onClick={handleShow}>
                {props.link}
            </Link>

            <Modal size='lg' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body closeButton>
                    <div class="ratio ratio-16x9">
                        <iframe src={props.pres + "#view=FitH"} title={props.prestitle} allowfullscreen></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

const Activity = () => {
    return (
        <main>
            <div class='row pt-4 pb-2'>
            <h1>The Activity</h1>

                <div className="col-md-9 col-12">
                    <p>
                        For my activity, I separated the students into three groups of 7-10 students who would work together to perform a small script (memorization optional but not required). My goal was to support and rehearse with them so that they would be able to read aloud fluently and comprehensibly, and understand the material of each group's performance.
                    </p>
                    <p>I chose this activity because it encourages collaboration and is entertaining enough to increase interest in language-learning. It's also broadly useful because the subject, grammar, and language/age level can be adapted just by choosing different scripts. That said, an integral part of the activity involves reading, so the majority of the students must be able to read aloud in English.</p>
                    <p>
                        I made a concerted effort to make the activity as accessible and inclusive as possible. While these details may be included throughout the site, please visit my dedicated <Link to='/accessibility'>Accessibility</Link> page for a comprehensive account.
                    </p>
                </div>
                <div class="col-md-3 col-sm-12">
                <figure class="figure">
                    <img src={image} class="figure-img img-fluid" alt="Props: Fake mustaches, peasant outfit, cowboy hat, crown, jewelry, cow-print fabric, toy cat, scarf"></img>
                    <figcaption class="figure-caption">Examples of props and accessories</figcaption>
                </figure>
                </div>
            </div>
            <hr class="col-3 col-md-2 mb-4"></hr>

            <div class="py-1">
                <div class="row" >
                    <h2>Activity Breakdown</h2>
                    <div class="col-lg-4 col-md text-break">
                        <nav id="navbar-example3" class="position-sticky flex-column align-items-stretch pe-4 border-end" style={{ top: '2rem' }}>
                            <nav class="nav nav-pills flex-column scroll-nav">
                                <a class="nav-link" href="#prep">Preparation</a>
                                <nav class="nav nav-pills flex-column">
                                    <a class="nav-link ms-3" href="#scripts">Scripts</a>
                                    <a class="nav-link ms-3" href="#collab">Instructor Collaboration</a>
                                    <a class="nav-link ms-3" href="#materials">Additional Materials</a>
                                </nav>
                                <a class="nav-link" href="#lessons">Lessons</a>
                                <nav class="nav nav-pills flex-column">
                                    <a class="nav-link ms-3" href="#intro">Part 1: Introduction and Casting</a>
                                    <a class="nav-link ms-3" href="#practice">Part 2: Practice</a>
                                    <a class="nav-link ms-3" href="#pres">Part 3: Presentation</a>
                                </nav>
                                <a class="nav-link" href="#reflect">Reflection</a>
                            </nav>
                        </nav>
                    </div>

                    <div class="col-lg-8 col-md">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" class="scrollspy-example-2">
                            <div id="prep">
                                <h3 class="h4">Preparation</h3>
                                <p>Preparation involved finding scripts, props, and costumes. I've also made sure to collaborate with the instructors of the participating classes to ensure success.</p>
                            </div>
                            <div id="scripts">
                                <h4 class="h5">Scripts</h4>
                                <p>I found a selection of easy scripts depicting 5-10 minute short stories. I decided full story arcs would be most satisfying and understandable to students, and the short length kept it achievable for small groups.</p>

                                <p>I dedicated a significant amount of time to researching texts, since appropriate scripts were essential for the success of the activity. I also needed to make sure I had the correct licensing to print, use, and edit the texts. Permission to make changes is particularly difficult to obtain, but it was important to me since I wanted to be able to customize the grammar and material for the class (and even individual students).</p>

                                <p>To this end, I reached out by email to websites that had scripts I was interested in. Thus far, publishers and authors have been happy to let me use and even adapt material as long as it's for educational use and won't be published anywhere.</p>

                                <p>Unfortunately, that means I can't share the scripts here. However, if you'd like information about where I found the scripts, visit the <Link aria-label="link to Resources page" to="/resources">Resources</Link> page.</p>

                                <p>Since I wanted to incorporate class content into the activity, I compared potential scripts with the class textbooks for Social Science, Natural Science, and English to find texts with relevant content. I also considered scripts that were stories that I knew from childhood, since that seemed like a great opportunity to tie in cultural knowledge. Ultimately, I chose the popular fairytale <i>Rumplestiltskin</i>, one childhood story, and one completely new work. I think it ended up being a great balance.</p>

                                <p>I then substantially edited them to use relevant vocabulary and grammatical structures. For instance, I made changes so the verb tenses were ones the students had learned or were currently studying.</p>

                                <p>Lastly, I made certain the scripts had a balance of acting parts and narrator parts, with some flexibility so I could adjust if needed. While I think it's important for everyone to participate, I didn't want to force anyone to act if they didn't want to.</p>
                            </div>
                            <div id="collab">
                                <h4 class="h5">Instructor Collaboration</h4>
                                <p>Collaborating with the teacher for each class was essential. I worked with them to organize around their schedule and choose material that would complement their lessons. They were also very helpful in providing information about the curriculum and helping me develop content that the students would understand.</p>

                                <p>I also asked if there were any students that have a lower level of English or who would otherwise need accommodations. There are many reasons a student might have difficulties reading aloud (or otherwise participating in the activity). We spoke about it far enough in advance for me to be able to adjust my choice of scripts and dialogue. While each part had similar amounts and levels of speaking, I was also able to vary that based on student needs.</p>
                            </div>
                            <div id="materials">
                                <h4 class="h5">Additional Materials</h4>
                                <p>I spent some time finding and making some small props and costumes. For <i>Rumplestiltskin</i>, for example, I brought a blanket (to look like a swaddled baby), a crown, and some other medieval-looking accessories. I also used school supplies to make a paper spinning wheel. Much of this was done before the activity began, but space for students' ideas and collaboration on this element was also built in.</p>

                                <p>A selection of these props are shown in the figure at the <a href="#top">top</a> of this page.</p>

                                <p>The students are allowed to bring things if they want as well. However, I decided to do most of it myself to give the students more time to focus on the language-learning aspects of the activity.</p>

                                <p>Finally, I made a short slide deck to be used during the performance as a "set" to change the scene or to add context to narration. This was a great way to incorporate <abbr title="Information and Communication Technology">ICT</abbr> into the activity. More importantly, I think it's helpful for setting the scene and clarifying things for the audience. Like the props, some of this was done as preparation, and some reserved for after student discussion.</p>
                            </div>
                            <div id="lessons">
                                <h3 class="h4">The Lessons</h3>
                                <p>I worked with the professor of each class I intended to do the activity with, and I adapted the activity to whatever number of days they felt was appropriate for their classes. I ended up with a (partial) class to introduce the activity, several classes to practice with the groups, and a class for the performances.</p>
                            </div>
                            <div id="intro">
                                <h4 class="h5">Part 1: Introduction and Casting</h4>
                                <p>
                                    I used the first day to introduce the activity. I gave a short presentation to facilitate understanding and additionally incorporate <abbr title="Information and Communication Technology">ICT</abbr>. I explained the activity and used a combination of pictures and text in my presentation to introduce each of the stories we would be using. It allowed me to gauge understanding and give the whole class background information like context and story-specific vocabulary. This way, they're able to better understand their own scripts and, eventually, the other students' performances.</p>

                                    <p>A truncated version of the presentation is available as a <abbr title="Portable Document Format">PDF</abbr> to <PPModal aria-label="view presentation as a PDF pop-up" link='preview' title='Presentation for Class' body={'asdfasdf'} pres={presentation} prestitle='Intro Presentation' /> or <a aria-label="Intro Presentation, PDF download" href={presentation} download="intro_presentation.pdf">
                                        download
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {download}
                                        </span>

                                    </a>
                                    . For the copyright reasons explained above in the Scripts section, I have removed references to some of the scripts.
                                </p>

                                <p>After the presentation (and time for questions), we moved on to casting. I briefly went student by student, asking if they'd prefer an acting part or a narration part. I then assigned them mostly randomly within that (I did keep a few acting and narration parts reserved for any students who I knew needed accomodation). This strategy allowed me to assign parts without taking too much class time or giving anyone an unfair advantage for picking their role.</p>
                                <p>Extra work was needed outside of class to make minor adjustments to the scripts based on the final number of narrators and actors. However, as previously mentioned, I was aware this would be necessary and chose scripts that would be easily adapted for this purpose.</p>
                            </div>
                            <div id="practice">
                                <h4 class="h5">Part 2: Practice</h4>
                                <p>This part of the activity is ongoing.</p>
                                <p>I wanted an opportunity to speak with each group at least once. Since this involves working with small groups, the main teacher and I have worked and continue to work together to plan time with multiple stations in the class, one of which being my activity.</p>

                                <p>This allows me to work with each group individually. I'm able to answer any questions, correct pronuciation, and help them plan out their performance. It's also an opportunity to discuss props or costumes. It's dependent on the script, but small props can be useful for context. The same goes for the slide deck created for the set. I've found students also get excited about props and costumes, which helps keep them engaged. I've made sure every part has at least one accessory or prop.</p>
                            </div>
                            <div id="pres">
                                <h4 class="h5">Part 3: Presentation</h4>
                                <p>I decided to delay the date of the final performances in favor of adding extra practice time, as I believe that it provides the greatest benefit to the students. While the performance is a great goal, I don't want to sacrifice valuable opportunities for language-learning.</p>
                                
                                <p>The performances will be done informally in the classroom in front of the class. I want to keep it low pressure, since the main focus is on language-learning and not acting skills. I will bring any props and costumes we discussed, and have some extra copies of scripts for any students who forget theirs. I also plan to check in with each group on the performance day, to make sure they're feeling comfortable and don't have any last-minute questions.</p>
                            </div>
                            <div id="reflect">
                                <h3 class="h4">Reflection</h3>
                                <p>Overall, I think the activity has gone very well. I've accomplished my stated goals, and the project has gone well. I definitely believe that this could be a useful tool for educators.</p>
                                
                                <p>As I mentioned in the overview at the top of this page, my goal was for the students to be able to read aloud well and understand the material. Everything above has been designed to facilitate this. A separate but equally important goal was ensuring that the activity was accessible and didn't cause anyone distress or discomfort. In my experience, it's very difficult to learn and retain things if the material is making you miserable, so I've specifically tried to make the activity as engaging, interactive, and inclusive as possible.</p>

                                <p>I think that I've met these goals. The longer duration of the activity means that the performances have not yet occurred, but the progress up to now makes me confident that the performances will go well. The students seem interested and clearly understand the activity.</p>

                                <p>A major takeaway is that it's so important to build in plenty of time. It makes everything go much smoother. You don't have to worry about holdups like absences or new students, and can take as much time as everyone needs so that they can feel and be successful.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </main>
    );
};

export default Activity;