import React from "react";
import { AccessibilityResources } from "./Resources";

const Accessibility = () => {
    return (
        <main>
            <div class='row pt-4 pb-2'>
                <h1>Accessibility and Inclusion</h1>
                <p class="lead">For information on web accessibility, <a aria-label="link to web accessibility section" href="#web">click here</a> or scroll to the bottom.</p>
                <p>
                    I believe strongly in diversity, equity, and inclusion. Accessibility was a very high priority throughout this project. While I am certainly not an expert, I carefully considered each aspect of the activity to make sure that every one of my students would be able to fully participate. I did additional research into creating accessible and inclusive activities. While there are certain classroom accessibility measures outside the scope of my position as a language assistant, I've done everything I can to promote <abbr title="Diversity, Equity, and Inclusion">DEI</abbr> and accessibility for the activity.
                </p>
                <p>
                    This primarily took the form of adapting the activity for any students I knew needed accomodation, following general accessibility guidelines for class content, and building in flexibility by designing multiple ways to engage with each step of the activity.
                </p>
                <h2>Designing an Accessible Activity</h2>
                <p>While preparing, I spoke with the teachers for each class to ask about any students that would need a modified activity for the actual performance.</p>

                <p>It's important to note that all I needed was information like 'this student won't be able to read aloud' or 'this student needs material at a lower level of English'. It's completely unnecessary to know any specific medical conditions or personal information that a student or their family might prefer not to share.</p>

                <p>I also kept student needs in mind when selecting material. Since I don't know the students' individual needs very well, and certainly shouldn't expect them to be their own advocates, I tried to select material that was appropriate and didn't require content warnings.</p>

                <p>On top of that, I gave students the option during casting to either be a narrator or an actor, since giving more options for how to participate can help any students who might have otherwise slipped through the cracks.</p>

                <h3>Providing information</h3>

                <p>I've made sure to follow current guidelines for text, contrast, <abbr title="etcetera">etc</abbr>. in my presentation and the printed scripts. I can only give out printed copies of the scripts for copyright reasons, though I did confirm with the teacher that there weren't any students who required additional accomodations for reading or otherwise accessing the material.</p>

                <p>I explained to the students exactly how the activity would be structured, and let them know that they could ask me or the main teacher if they had any questions or concerns. I've tried to provide all the information and material with as much advance notice as possible.</p>

                <h3>External Resources</h3>
                <p>Here are the most useful resources I found when researching accessible and inclusive activities. While some of the advised measures may seem excessive, I try to make things as accessible as possible to help any students who may have accessibility needs I'm not aware of.</p>
                <AccessibilityResources />

                <h2 id="web">Web Accessibility</h2>
                <p>I am not a professional web developer. However, to the best of my ability, I designed and programmed this website in compliance with the Web Content Accessibility Guidelines (<abbr>WCAG</abbr>) 2.2, which are the international web accessibility standards (as of January 2024). You can read more on the official website of the <abbr title="World Wide Web Consortium">W3C</abbr>, which provides a <a aria-label="link to WCAG webpage" href="https://www.w3.org/WAI/standards-guidelines/wcag/"><abbr>WCAG</abbr> 2 overview</a>.</p>

                <p>While the site has been tested with automated web accessiblity checkers online, it has not been independently analyzed beyond that.</p>

                <p>If you have any specific concerns about the accessibility of this website, feel free to <a class="text-break" href="mailto:nalcap.project.accessibility@gmail.com">nalcap.project.accessibility@gmail.com</a>.</p>
            </div>
        </main>

    );
};

export default Accessibility;