import React from "react";
import { Link } from 'react-router-dom';
import '../components/cover.css';

const Sitemap = () => {
    return (
        <main>
            <div class='row pt-4 pb-2'>
                <div className="col-md-9 col-12">
                    <h1>Sitemap</h1>
                    <p>This is a plain list of pages on the website.
                    </p>
                    <ul class="text-break">
                        <li>
                            <Link aria-label="link to homepage" to="/">Home</Link>
                        </li>
                        <li>
                            <Link aria-label="link to page with details on the activity" to="/activity">Activity</Link>
                            <ul>
                                <li><Link aria-label="link to preparation section of Activity page" to="/activity#prep">Preparation</Link></li>
                                <li><Link aria-label="link to lessons section of Activity page" to="/activity#lessons">Lessons</Link></li>
                                <li><Link aria-label="link to reflection section of Activity page" to="/activity#reflect">Reflection</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link aria-label="link to resources page" to="/resources">Resources</Link>
                        </li>
                        <li>
                            <Link aria-label="link to page with accessibility information" to="/accessibility">Accessibility</Link>
                            <ul>
                                <li><Link aria-label="link to web accessibility information" to="/accessibility#top">Activity Accessibility</Link></li>
                                <li><Link aria-label="link to web accessibility information" to="/accessibility#web">Web Accessibility</Link></li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>
        </main>
    );
};

export default Sitemap;