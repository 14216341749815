import React from "react";
import { Link } from 'react-router-dom';

const AccessibilityResources = () => {
    return (
        <div>
            <ul>
                <li class="pb-2">
                    <a href="https://webaim.org/resources/contrastchecker/">Web<abbr title="Accessibility in Mind">AIM</abbr> Contrast Checker</a> - This provides an easy way to check if color contrast meets accessibility standards (<abbr title="that is">ie</abbr> if the text is visible against the background color for people with low vision). It's meant for web accessibility, but can also be used for checking presentations, flyers, and other media.
                </li>
                <li class="pb-2">
                    <a href="https://accessibility.umn.edu/what-you-can-do/extend-core-skills/use-inclusive-teaching-strategies/classroom-activities">Classroom Activities: Accessible <abbr title="university">U</abbr></a> - This is a helpful website by the University of Minnesota on accessible class activities. I found the "Do's and Dont's" tab to be helpful.
                </li>
                <li class="pb-2">
                    <a href="https://udlguidelines.cast.org/?utm_source=castsite&lutm_medium=web&utm_campaign=none&utm_content=aboutudl">Universal Design for Learning Guidelines</a> - This webpage provides an official overview on Universal Design for Learning (<abbr>UDL</abbr>), which is a popular framework for teaching in a way that supports all student needs.
                </li>
                <li class="pb-2">
                    <a href="https://www.washington.edu/accesstech/documents/">Documents - Accessible Technology</a> - The University of Washington's easy-to-follow overview and checklist for creating accessible <abbr>PDF</abbr>s.
                </li>
            </ul>
        </div>
    );
}

const Resources = () => {

    return (
        <main>
            <div class='row pt-4 pb-2'>
                <div>
                    <h1>Resources</h1>
                    <p>I used a wide variety of external resources while designing this project. Below are some of the most helpful sources, all of which I would recommend to anyone creating a similar activity.
                    </p>
                </div>
            </div>
            <hr class="col-3 col-md-2"></hr>
            <h2>General Information</h2>
            <ul>
                <li class="pb-2"><a href="https://www.readingrockets.org/classroom/classroom-strategies/readers-theater">Reader's Theater</a> - Reader's Theater is just reading from scripts (not acting). This was a great foundation for me to build on, however, and it has tons of great advice. And in any case, Reader's Theater is also a great activity that I highly recommend.</li>
                <li class="pb-2"><a href="https://www.aep-arts.org/theatre-counts/">Theater Education</a> - This site discusses how beneficial theater education is for children. While this activity was a pretty narrow scope, I thought this was really interesting.</li>
                <li class="pb-2"><a href="https://www.teachingenglish.org.uk/teaching-resources/teaching-secondary/activities/pre-intermediate-a2/using-plays-language-class">Using plays in the language class</a> - A great overview on the use of plays and acting in language classes. It has some great tips for selecting material.</li>
            </ul>
            <h2 id="scripts">Scripts</h2>
            <p>These are some of the sites I used to find scripts. The copyright rules vary by site and script. If you can't find scripts that work for you, consider writing your own story (or a retelling of public domain works like fairytales).</p>
            <ul>
                <li class="pb-2"><a href="https://www.lazybeescripts.co.uk/Scripts/">Lazy Bee Scripts</a> - An easy-to-browse selection in a wide variety of types, themes, and age groups. Scripts can be previewed in full, but do need to be purchased for most use cases.</li>
                <li class="pb-2"><a href="http://www.thebestclass.org/rtscripts.html">The Best Class</a> - An index of free Reader's Theater scripts. Reader's theater scripts are great, especially if you want scripts with narrators.</li>
                <li class="pb-2">If these sites don't help, you can find other similar material by using search terms like 'reader's theater', 'short plays for kids', or '<abbr title="English as a Second Language">ESL</abbr> plays'.</li>
                <li class="pb-2">If you have a particular children's book in mind, consider visiting the website of the author or publisher (for children's books, authors are often easier to contact). They may be willing to allow you to use their material in class.
                </li>
            </ul>
            <h2>Accessibility</h2>
            <p>I did a significant amount of research into designing an inclusive and accessible activity. You can read more on my <Link to='/accessibility'>Accessibility</Link> page, but here are some of the resources I found helpful.</p>
            <AccessibilityResources />
        </main>
    );
};

export {
    Resources, AccessibilityResources
}