import React from "react";
import masks from '../components/masks.svg';

const Home = () => {
    return (
        <main>
            <section>
                <div className="row py-5">
                    <div className="col-md-8">
                        <h1 className="fw-light">Learning Language<br></br>Through Theater</h1>
                        <p className="lead">
                            For my final project as an <abbr title="North American Language and Culture Assistants Program">NALCAP</abbr> language assistant, I designed an activity combining reading and live performance of short stories and plays, fostering English language learning through enjoyable and creative means.
                        </p>
                    </div>
                    <div class="col-md-4 d-none d-lg-block">
                        <img class="ps-5 figure-img img-fluid" src={masks} alt="theater masks"></img>

                    </div>
                </div>
            </section>
            <hr class="col-3 col-md-2 mb-4"></hr>

            <div class="row g-5">

                <div class="col-md-6">
                    <h2>Overview</h2>
                    <p>I helped 5th grade classes read and act out short plays in groups to learn and practice English. This included finding scripts that aligned with the course material and student needs, introducing the texts to the students via a presentation, and finally supporting them as they developed short skits to perform for the class.</p>
                </div>
                <div class="col-md-6">
                    <h2>Class Specifications</h2>
                    <p>Topic: Learning Grammar through Theater</p>
                    <p>Level: <span lang='es'>Educación Primaria</span></p>
                    <p>Course: Fifth Grade</p>
                </div>
            </div>
        </main>

    );
};

export default Home;